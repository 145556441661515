.band--footer {
  @include mq($bp-medium, max) {
    background: $color-grey-lighter;
  }

  @include mq($bp-grid-medium-responsive) {

    .row {
      flex-wrap: nowrap !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}
