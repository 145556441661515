@import 'alerts',
  'buttons',
  'headings',
  'links',
  'lists',
  'status',
  'tables',
  'text';

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

.align {

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }

  &--center {
    text-align: center !important; /* stylelint-disable-line declaration-no-important */
  }
}
