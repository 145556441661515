.link--account {
  // border: 1px solid red;
  display: block;
  height: 40px;
  padding-right: 45px;
  position: relative;

  @include mq($bp-small, max) {
    margin-right: 10px;
  }

  .icon--size-5 {
    position: absolute;
    right: 0;
    top: 2px;
  }

  .icon--size-2 {
    top: -4px;
    transition: transform $transition-duration $transition-timing;

    .show-account & {
      transform: rotate(180deg);
    }

    @include mq($bp-small, max) {
      left: 100%;
      position: absolute;
      top: 4px;
    }

    @include mq(400, max) {
      width: 10px;
    }
  }

  svg {
    fill: $color-primary;
  }

  @include mq($bp-small, max) {
    height: 24px;
    padding-right: 24px;

    .icon--size-5 {
      height: 24px;
      top: 0;
      width: 24px;
    }
  }

  &:hover {

    svg {
      fill: $color-tertiary;
    }
  }
}

.account-link {
  position: relative;

  &__name {
    display: inline-block;
    max-width: 150px;
  }

  &__text {
    display: none;

    @include mq($bp-small) {
      display: inline;
    }
  }
}

.account {
  @include mq($bp-showmodal, max) {
    left: 50%;
    max-width: 400px;
    padding: $gutter;
    position: absolute;
    top: 80px;
    transform: translateX(-50%);
    width: calc(100% - 20px);
  }

  &-wrapper {
    background: $color-grey-lighter;
    box-sizing: border-box;
    display: none;
    margin-top: 5px;
    overflow: hidden;
    position: absolute;
    right: 10px;
    top: 100%;
    width: 240px;

    &--form {
      width: 280px;
    }

    @include mq($bp-showmodal, max) {
      bottom: 0;
      box-sizing: border-box;
      left: 0;
      margin: 0;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      padding-top: 80px !important; /* stylelint-disable-line declaration-no-important */
      position: fixed !important; /* stylelint-disable-line declaration-no-important */
      right: 0;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    @include mq($bp-showmodal) {
      box-shadow: 0 20px 100px 10px transparentize($color-black, .6);
    }

    .show-account & {
      display: block;
    }
  }

  form {
    padding: 20px;

    .control {
      margin-bottom: 10px;
    }

    button {
      margin-bottom: 20px;

      @include mq($bp-showmodal, max) {
        margin-top: 10px;
      }
    }
  }
}

.account-overlay {
  bottom: -200px;
  display: none;
  left: 0;
  position: absolute;
  right: 0;
  top: -200px;
  width: 100%;
  z-index: 3;

  .show-account & {
    display: block;
  }
}

.show-account {

  .band--header {

    .band__content {
      position: static;
    }
  }

  .column--account {
    position: relative;
    z-index: 5;
  }
}
