.table-wrapper {
  margin-bottom: $margin-bottom;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden;

  @include mq($bp-large) {
    overflow-x: visible;
    overflow-y: visible;
  }

  .table {
    margin-bottom: 0;
    max-width: 99.99%;
  }
}

.table {
  $self: &;

  background-color: $color-table-cell-bg;
  box-sizing: border-box;
  margin-bottom: $margin-bottom;
  text-align: left;
  width: 100%;

  &--border {
    border: 1px solid $color-table-border;
  }

  &__cell {
    padding: $table-cell-spacing-ver $table-cell-spacing-hor;
    text-align: left;
    vertical-align: middle;

    #{ $self }__head & {
      padding: $table-cell-spacing-hor;
    }

    &--no-stretch {
      width: 1px;
    }

    #{ $self }--column #{ $self }__head #{ $self }__row &,
    #{ $self }--row &:first-child {
      font-weight: bold;
    }

    #{ $self }--column #{ $self }__head #{ $self }__row & {
      border-bottom: 1px solid $color-table-border;
    }
  }

  &--column &__row &__cell:nth-child(odd) {
    background-color: $color-table-cell-bg-alt;
  }

  &--default #{ $self }__body &__row:nth-child(odd),
  &--row #{ $self }__body &__row:nth-child(even) {
    background-color: $color-table-cell-bg-alt;
  }

  @include mq($bp-responsive-table, max) {

    &--responsive {
      background: $color-white;

      #{ $self }__head {
        display: none;
      }

      #{ $self }__row {
        background-color: $color-table-cell-bg;
        display: flex;
        flex-wrap: wrap;
        justify-content: stretch;
        padding: 5px;

        &:not(:last-child) {
          margin-bottom: $margin-bottom / 2;
        }
      }

      #{ $self }__cell {
        box-sizing: border-box;

        &--no-stretch {
          width: inherit;
        }

        .hide {
          display: inline-block !important; /* stylelint-disable-line declaration-no-important */
          max-width: 120px;
          min-width: 80px;
          width: 50%;
        }
      }
    }
  }
}
