.band--featured {
  @include mq($bp-xlarge) {
    margin-bottom: $gutter * 6;
  }

  .featured {
    box-sizing: border-box;
    left: 0;
    margin: 0 $gutter;
    position: relative;
    right: 0;
    top: -60px;

    @include mq($bp-small) {
      margin: 0 ($gutter * 2);
    }

    @include mq(880) {
      box-sizing: border-box;
      left: 50%;
      margin: 0;
      right: auto;
      transform: translateX(-50%);
      width: 800px;
    }

    @include mq($bp-xlarge) {
      bottom: -80px;
      left: auto;
      padding: 40px;
      position: absolute;
      right: 0;
      top: auto;
      transform: translateX(0);
      width: 50%;
    }

    .text {
      position: relative;
      top: -5px;
    }
  }

  .row > .column {
    flex: 1;

    &:first-child {
      flex: 0 0 150px;
    }
  }
}
