.contactblock {
  font-style: italic;

  .band--header & {
    @include mq($bp-large, max) {
      position: relative;
      top: 4px;

      .js-contact {
        display: block;
      }
    }
  }

  .band--nav & {
    color: $color-white;

    @include mq($bp-showmodal, max) {
      display: none;
    }

    svg {
      fill: $color-white;
    }

    .link {
      color: $color-white !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}
