.focus {
  $self: &;

  &--field:checked {

    & ~ #{$self}--box {
      display: block;
    }
  }

  &--box {
    display: none;
    padding-top: $gutter;
    width: 100%;

    span {
      display: inline;

      &::before,
      &::after {
        display: none;
      }
    }
  }
}
