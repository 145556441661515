@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }
}

.js-item-added {

  .link--checkout {
    animation-duration: 400ms;
    animation-fill-mode: both;
    animation-iteration-count: 3;
    animation-name: bounce;
    animation-timing-function: linear;

    &__status {
      background-color: $color-status-ok;
    }
  }
}

.modal--order {
  background: $color-white;
  border: 4px solid $color-primary;
  box-shadow: 0 0 1000px 100px transparentize($color-black, .4);
  box-sizing: border-box;
  display: none;
  left: 50%;
  max-width: 90%;
  padding: $gutter;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  z-index: 9999;

  .js-item-added & {
    display: block;
  }

  .button {

    &:last-child {
      margin-top: 10px;
    }
  }
}

.modal--bg {
  background: transparentize($color-white, .5);
  box-sizing: border-box;
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9998;

  .js-item-added & {
    display: block;
  }
}
