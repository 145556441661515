.list {
  margin-bottom: $margin-bottom;

  &--numbers {
    list-style: decimal;

    li {
      margin-left: 20px;
    }
  }

  &--bullets {
    list-style-type: disc;

    li {
      margin-left: 20px;
    }
  }

  &--lines {

    li {
      border-bottom: 1px solid $color-border;
      padding: ($gutter / 4) 0;

      &:first-child {
        border-top: 1px solid $color-border;
      }
    }
  }

  &--inline {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include mq($bp-medium) {

      &--left {
        justify-content: flex-start;

        li {
          margin: 0 20px 0 0 !important; /* stylelint-disable-line declaration-no-important */
        }
      }

      &--right {
        justify-content: flex-end;
      }
    }

    li {
      display: flex;
      flex: 0 0 auto;
      margin: 0 10px;

      .icon {
        padding: 5px 10px 0 0;
      }

      &.text--no-wrap p {
        margin-bottom: 0;

        a {
          color: $color-black;
          text-decoration: none;
        }
      }
    }
  }

  &--icons {
    margin-bottom: $gutter / 2;

    li {
      margin-bottom: $gutter / 2;
      padding-left: 25px;
      position: relative;

      .icon {
        left: 0;
        position: absolute;
        top: 3px;
      }
    }
  }
}

.breadcrumb {

  li {
    @include text-overflow();

    display: inline-block;
  }
}
