.banner {
  margin-bottom: $margin-bottom;

  .row {
    overflow: hidden;

    @include mq($bp-medium, max) {
      background-color: $color-grey-lighter;
      padding-bottom: 10px;
    }

    @include mq($bp-medium) {
      flex-wrap: nowrap;
    }
  }

  &__image {
    max-width: 200px;

    @include mq($bp-medium, max) {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }

  &__content {
    position: relative;

    @include mq($bp-medium, max) {
      flex: 0 0 80%;
      max-width: 80%;
    }

    @include mq($bp-medium, max) {
      font-size: 16px !important; /* stylelint-disable-line declaration-no-important */
      line-height: 1;
    }

    &::after {
      background-color: $color-grey-lighter;
      bottom: - ($gutter / 2);
      content: '';
      left: 50%;
      position: absolute;
      top: - ($gutter / 2);
      transform: translateX(-50%);
      width: 100vw;
      z-index: -1;
    }
  }

  &__button {
    min-width: 200px;
  }
}
