.link,
a {
  color: $color-primary;
  text-decoration: underline;
  transition: all $transition-duration $transition-timing;

  &:hover {
    color: $color-tertiary;
  }

  &--black {
    color: $color-black;

    &:hover {
      color: $color-primary;
    }
  }

  &--white {
    color: $color-white;

    &:hover {
      color: $color-white;
    }
  }

  &--shippingfast {
    position: relative;
    top: 4px;

    @include mq($bp-small, max) {
      top: 0;

      .icon {
        height: 20px;
        width: 30px;
      }
    }

    &:hover {

      .icon {

        svg {
          fill: $color-tertiary;
        }
      }
    }
  }

  &--orderlist {
    display: block;
    position: relative;

    .icon {
      display: block;
      height: 30px;
      top: -4px;
      width: 30px;

      @include mq($bp-small, max) {
        height: 20px;
        width: 20px;
      }
    }

    &:hover {

      .icon {

        svg {
          fill: $color-tertiary;
        }
      }
    }
  }

  &--checkout {
    display: block;
    position: relative;
    text-decoration: none;

    .icon {
      display: block;
      height: 32px;
      top: -2px;
      width: 40px;

      @include mq($bp-small, max) {
        display: inline-block;
        height: 20px;
        width: 28px;
      }
    }

    &__status {
      background-color: $color-secondary;
      border-radius: 500px;
      color: $color-white;
      font-weight: bold;
      line-height: 20px;
      min-height: 20px;
      min-width: 20px;
      padding: 2px;
      position: absolute;
      right: -5px;
      text-align: center;
      top: -10px;

      @include mq($bp-small, max) {
        display: inline-block;
        line-height: 16px;
        min-height: 16px;
        min-width: 16px;
        position: relative;
        right: 10px;
        top: auto;
      }

      @include mq(400, max) {
        @include font-size(10);

        line-height: 12px;
        min-height: 12px;
        min-width: 12px;
      }
    }

    &:hover {

      .icon {

        svg {
          fill: $color-tertiary;
        }
      }
    }
  }

  .header-icon-links & {
    display: inline-block;
    margin-left: $gutter / 4;
    position: relative;
    text-decoration: none;
    top: 5px;

    .icon {
      display: inline-block;
      height: 36px;
      padding: 0;
      top: -2px;
      width: 32px;

      @include mq($bp-xsmall) {
        width: 40px;

        svg {
          max-height: 30px;
        }
      }
    }

    &:hover {

      .icon {

        svg {
          fill: $color-tertiary;
        }
      }
    }
  }
}
