html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  -webkit-text-size-adjust: 100%; /* stylelint-disable-line property-no-vendor-prefix */
}

body {
  @include default-font(nth($font-sizes, 2));

  background: $color-body;
  color: $color-text;
  margin: 0;
  overflow-x: hidden;
}

::selection {
  background: $color-grey-lighter;
  color: $color-primary;
}

@import 'bands',
  'footer',
  'grid',
  'header',
  'layout',
  'webshop';
