.js-tabby .tabs-pane {
  clear: both;
  clip: rect(0 0 0 0);
  display: block;
  height: 1px;
  overflow: hidden;
  padding: $gutter / 2;
  position: absolute;
  width: 100%;
  z-index: 1;

  @include mq($bp-medium) {
    border-radius: 0 0 $border-radius $border-radius;
    margin-bottom: $margin-bottom;
  }

  &.active {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }

  &:focus {
    outline: none;
  }

  &--alt {
    background: $color-table-alt !important; /* stylelint-disable-line declaration-no-important */

    @include mq($bp-medium) {
      border-radius: 0;
    }
  }
}

.tabs {
  display: flex;
  position: relative;
  top: 1px;
  z-index: 2;

  @include mq($bp-medium, max) {
    display: none;
  }

  li {
    margin-right: 4px;

    a {
      border-radius: 5px 5px 0 0;
      display: block;
      padding: 10px;
    }

    &.active {

      a {
        background-color: $color-white !important; /* stylelint-disable-line declaration-no-important */
        border-bottom: 1px solid $color-white !important; /* stylelint-disable-line declaration-no-important */
        font-weight: bold;
      }
    }
  }

  &--alt {
    padding: ($gutter / 2) 0 0 ($gutter / 2);
    top: 0;

    li {

      a {
        background: $color-white;
        border: 0 !important; /* stylelint-disable-line declaration-no-important */
        color: $color-text;
        padding: ($gutter / 4) ($gutter / 2);
      }

      &.active {

        a {
          background-color: $color-table-alt !important; /* stylelint-disable-line declaration-no-important */
          border-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
          color: $color-primary;
        }
      }
    }
  }

  &-pane__link {
    background-color: $color-grey-lighter;
    border: 1px solid $color-border;
    display: block;
    margin: 2px 0 0;
    padding: $gutter / 2;

    &--alt {
      background-color: $color-white;
      border: 1px solid $color-table-alt;
    }

    @include mq($bp-medium) {
      display: none;
    }

    @include mq($bp-medium, max) {

      + .active {
        border-top: 0;
        margin-bottom: $margin-bottom / 2 !important; /* stylelint-disable-line declaration-no-important */
      }
    }
  }
}

.tabs-wrapper--alt {
  @include mq($bp-medium, max) {
    box-sizing: border-box;
    padding: $gutter / 2;
  }
}
