.figure {
  $self: &;

  margin-bottom: $margin-bottom;
  max-width: 100%;
  position: relative;

  &--overlay {

    &::after {
      background: transparentize($color-black, .6);
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }

    #{$self}__caption {
      left: 50%;
      max-width: 480px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      user-select: none;
      z-index: 2;
    }
  }

  @include mq($bp-grid-medium) {

    &--right {
      float: right;
      margin-left: $gutter;
      max-width: calc(50% - 10px);

      + #{$self}--right,
      + #{$self}--left {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &--left {
      float: left;
      margin-right: $gutter;
      max-width: calc(50% - 10px);

      + #{$self}--right,
      + #{$self}--left {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

img {
  display: block;
  margin: 0 auto;
  max-width: 100%;

  &.lazyload,
  &.lazyloaded,
  &.lazyloading {
    width: 100%;
  }
}
