.gallery {
  // margin-bottom: $margin-bottom;

  &__slide {
    box-sizing: border-box;
    width: 100%;

    .figure {
      margin-bottom: 0;

      @include mq($bp-grid-medium, max) {
        margin: 0 $gutter;
      }
    }

    img {
      max-width: 100%;
    }
  }

  &__thumbnails {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-bottom: $margin-bottom;

    li {
      cursor: pointer;
      flex: 1 1 auto;
      max-width: 80px;
      outline: none;

      .figure {
        margin: 0 2px;
      }

      img {
        border: 1px solid $color-white;
        margin: 0 auto;
        max-width: 80px;
        width: 100%;
      }

      &.tns-nav-active {

        img {
          border-color: $color-border;
        }
      }
    }
  }
}
