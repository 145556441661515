.filters {
  @include mq($bp-grid-medium, max) {
    border: 1px solid $color-border;
    border-radius: $border-radius;
    display: none;
    margin-bottom: $margin-bottom * 2;

    &.show {
      display: block;
    }
  }
}

.filter {
  margin-bottom: $gutter * 1.5;

  @include mq($bp-grid-medium, max) {
    margin-bottom: 0;
    padding: $gutter / 2;
  }

  .tabs-pane & {
    margin-bottom: 0;
  }

  &__title {
    border-bottom: 1px solid $color-border;
    display: block;
    margin-bottom: 10px;
    padding-bottom: 8px;
    padding-right: 50px;
    position: relative;

    > * {
      pointer-events: none;
    }

    .icon {
      position: absolute;
      right: 10px;
      top: 45%;
      transform: translateY(-50%);

      &--up {
        display: none;
      }
    }
  }

  &.js-close &__title {

    .icon--up {
      display: inline-block;
    }

    .icon--down {
      display: none;
    }
  }

  &__list {
    margin: 0 0 $gutter / 4;

    li {
      @include text-overflow();

      display: none;
      margin: 0;
      padding: 0;

      &:nth-child(-n+5) {
        display: block;
      }

      a {
        color: $color-text;
        display: inline-block;
        padding: 2px 0 0 24px;
        position: relative;
        text-decoration: none;

        &::after,
        &::before {
          content: '';
          display: block;
          height: 13px;
          left: 3px;
          line-height: 1;
          position: absolute;
          top: 5px;
          width: 13px;
        }

        &::after {
          border: 1px solid $color-grey;
        }

        &::before {
          height: 11px;
          left: 5px;
          top: 7px;
          width: 11px;
        }

        &:hover {
          color: $color-primary;
        }

        span.color {
          border-radius: 50%;
          display: inline-block;
          height: 14px;
          width: 14px;
        }

        span.count {
          @include font-size(12);

          color: $color-grey;
        }
      }

      &.check {

        a {
          color: $color-primary;
          font-weight: bold;

          &::before {
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#{$form-custom-selected-color}" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"/></svg>');
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
    }

    &.showall li {
      display: block;
    }
  }

  .tabs-pane &__list {
    margin: 0;

    @include mq($bp-xsmall) {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      @include mq($bp-xsmall) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @include mq($bp-grid-small) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }

      @include mq($bp-grid-medium) {
        flex: 0 0 25%;
        max-width: 25%;
      }

      @include mq($bp-grid-large) {
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
  }

  &.js-close &__list {
    display: none;
  }

  &.js-show-list &__list {

    li {
      display: block;
    }
  }

  .show-less,
  &.js-close .show-less,
  &.js-close.js-show-list .show-less {
    display: none;
  }

  &.js-show-list .show-less {
    display: block;
  }

  .show-more {
    display: block;
  }

  &.js-close .show-more,
  &.js-show-list .show-more {
    display: none;
  }
}

.row--togglefilters {
  @include mq($bp-grid-medium) {
    display: none;
  }
}
