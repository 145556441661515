.header {
  background: $color-white;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 106;

  @include mq($bp-small, max) {

    .band--header .column {
      margin: 0 5px;
    }
  }

  @include mq($bp-site-width) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: $bp-site-width + px;
  }

  .band--topbar {
    display: block;
    height: auto;
    max-height: 200px;
    overflow: hidden;
    // transition: max-height $transition-duration $transition-timing;

    @include mq($bp-small, max) {

      .body--webshop & {
        display: none;
      }
    }

    .band__content {
      padding: 10px 20px;
    }
  }

  .band--header {
    padding: ($gutter / 2) 0;

    @include mq($bp-small) {
      padding: $gutter 0;
    }

    .body--website & {
      @include mq($bp-showmodal, max) {
        border-bottom: 1px solid $color-border;
      }
    }

    .body--website.js-stickyheader & .band__content {
      @include mq($bp-showmodal, max) {
        padding-bottom: 15px;
        padding-top: 5px;
      }
    }

    .body--webshop & {
      z-index: 103;

      @include mq(400, max) {

        .band__content {
          padding-right: 10px;
        }

        .column {
          margin: 0 2px;
        }
      }
    }

    .button {
      margin-bottom: 0;
    }
  }

  .column--logo {
    max-width: 200px;
    // transition: all $transition-duration $transition-timing;

    @include mq($bp-grid-large) {
      max-width: 280px;
    }
  }

  &.js-sticky {

    .column--logo {
      max-width: 160px;

      @include mq($bp-grid-large) {
        max-width: 200px;
      }
    }

    .band--topbar {
      max-height: 0;
    }

    .band--header {
      padding: ($gutter / 2) 0;
    }
  }
}
