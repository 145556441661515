$grey-tones:
  #ffffff, #f5f5f5, #f0f0f0, #e3e3e3, #b1b1b1,
  #9b9b9b, #757575, #616161, #2c2c2c, #000000;

$color-primary:   #00538e;
$color-secondary: #ff7500;
$color-tertiary:  #0b8ec6;

$color-hover:     $color-tertiary;

// Grey tones
$color-white:    nth($grey-tones, 1);
$color-grey-100: nth($grey-tones, 2);
$color-grey-200: nth($grey-tones, 3);
$color-grey-300: nth($grey-tones, 4);
$color-grey-400: nth($grey-tones, 5);
$color-grey-500: nth($grey-tones, 6);
$color-grey-600: nth($grey-tones, 7);
$color-grey-700: nth($grey-tones, 8);
$color-grey-800: nth($grey-tones, 9);
$color-black:    nth($grey-tones, 10);

$color-grey-darker:   $color-grey-800;
$color-grey-dark:     $color-grey-700;
$color-grey:          $color-grey-500;
$color-grey-light:    $color-grey-400;
$color-grey-lighter:  $color-grey-200;

$rgba-color-grey-darker:   rgba($color-grey-darker, .99);
$rgba-color-grey-dark:     rgba($color-grey-dark, .99);
$rgba-color-grey:          rgba($color-grey, .99);
$rgba-color-grey-light:    rgba($color-grey-light, .99);
$rgba-color-grey-lighter:  rgba($color-grey-lighter, .99);

// Platform colors
$color-social-twitter:    #35ccff;
$color-social-facebook:   #03539e;
$color-social-linkedin:   #217bc1;
$color-social-whatsapp:   #25d366;
$color-social-instagram:  #8a3ab9;

// Status colors
$color-status-error:  #e41b13;
$color-status-ok:     #6abc0c;
$color-status-info:   #fff2af;

$rgba-color-status-error: rgba($color-status-error, .99);
$rgba-color-status-ok: rgba($color-status-ok, .99);
$rgba-color-status-info: rgba($color-status-info, .99);

// Global colors
$color-body:    $color-grey-lighter;
$color-text:    $color-black;
$color-border:  $color-grey-300;

$color-table-alt: transparentize(#91b5c7, .7);
