.close-button {
  display: none;

  @include mq($bp-showmodal, max) {
    background-color: $color-white;
    border: 1px solid $color-border;
    border-radius: 50%;
    box-shadow: 0 0 100px 50px transparentize($color-black, .8);
    display: none;
    height: 36px;
    position: fixed;
    right: $gutter / 2;
    text-align: center;
    top: $gutter / 2;
    width: 36px;
    z-index: 9999;

    .icon {
      top: 6px;
    }
  }

  @include mq($bp-showmodal) {
    cursor: default;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 99;

    .icon {
      display: none !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  // @include mq($bp-showmodal, max) {

  .show-account &,
  .show-menu &,
  .show-search & {
    display: block;
  }
  // }
}
