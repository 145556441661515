// Media Query Breakpoints

@mixin mq($point, $query1: min, $query2: width) {
  @if $query1 == 'max' {
    @media screen and (#{$query1}-#{$query2}: #{$point - 1}px) {
      @content;
    }
  }

  @else {
    @media screen and (#{$query1}-#{$query2}: $point+px) {
      @content;
    }
  }
}

// Mobile Device > Orientation

@mixin orient($orientation: landscape) {
  @media (orientation: $orientation) {
    @content;
  }
}

// Retina

@mixin retina() {
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) { /* stylelint-disable-line media-feature-name-no-vendor-prefix, media-feature-name-no-unknown */
    @content;
  }
}

// Ratio breakpoint

@mixin mq-ratio($ratio: max, $size1: 100, $size2: 100) {
  @media screen and (#{$ratio}-aspect-ratio: #{$size1}/#{$size2}) {
    @content;
  }
}

// Print

@mixin print {
  @media print {
    @content;
  }
}
