.link--search {
  display: none;

  .searchbox & {
    display: inline-block;
  }

  @include mq($bp-showmodal, max) {
    display: inline-block;
    height: 18px;
    line-height: 18px;
    text-align: center;
    width: 20px;

    @include mq($bp-grid-medium, max) {

      .icon svg {
        height: 22px;
        right: 7px;
      }
    }

    @include mq($bp-grid-medium) {
      height: 30px;
      line-height: 30px;
      width: 40px;
    }

    &:hover {

      svg {
        color: $color-tertiary;
      }
    }
  }
}

.search {

  &__suggestions {
    background: $color-white;
    border: 1px solid $color-border;
    box-shadow: 0 80px 100px 10px transparentize($color-black, .7);
    height: 500px;
    left: 0;
    max-height: calc(100vh - 140px);
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll; /* has to be scroll, not auto */
    position: absolute;
    right: 0;
    top: 100%;

    @include mq($bp-medium) {
      max-height: calc(100vh - 120px);
    }

    h3 {
      margin-bottom: 10px;
    }

    ul {
      margin: 0;
      padding: 0 10px 20px;

      .suggestions--search {
        margin-bottom: 20px !important; /* stylelint-disable-line declaration-no-important */
        padding: 20px 10px !important; /* stylelint-disable-line declaration-no-important */
      }

      ul {
        padding: 0;
      }

      li {
        display: block;
        line-height: 1;
      }
    }

    a {
      display: block;
      line-height: 1;
      overflow: hidden;
      padding: 5px 0;
      text-decoration: none;
      text-overflow: ellipsis;
      text-transform: lowercase;
      white-space: nowrap;

      &:hover {
        color: $color-primary;
        font-weight: bold;
      }
    }
  }

  .button--search {
    background-color: transparent !important; /* stylelint-disable-line declaration-no-important */
    border: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    svg {
      fill: $color-grey-light;
    }

    &:hover {

      svg {
        fill: $color-tertiary;
      }
    }
  }

  &__box {
    margin: 0 auto;
    max-width: 460px;
    position: relative;
  }

  &-wrapper {
    position: relative;
    z-index: 5;

    @include mq($bp-showmodal, max) {
      background-color: $color-grey-lighter;
      bottom: 0;
      box-sizing: border-box;
      display: none;
      left: 0;
      margin: 0;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      padding: 80px 20px 20px !important; /* stylelint-disable-line declaration-no-important */
      position: fixed !important; /* stylelint-disable-line declaration-no-important */
      right: 0;
      top: 0;
      width: 100%;
      z-index: 105;
    }

    .show-search & {
      display: block;
    }
  }
}

@include mq($bp-showmodal) {

  .searchbox {
    float: right;
    position: relative;
    width: 30px;

    @include mq($bp-showmodal, max) {
      position: static;
    }

    .search-wrapper {
      background: $color-white;
      border: 2px solid $color-border;
      box-shadow: 0 20px 100px 10px transparentize($color-black, .6);
      display: none;
      margin-top: 13px;
      min-width: 300px;
      padding: 10px;
      position: absolute;
      right: 0;
      top: 100%;

      .show-search & {
        display: block;
      }
    }
  }
}
