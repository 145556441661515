// DEFAULT FONT
// @include default-font();

$font-default: 'Open Sans', sans-serif;
$font-default-weight: normal;
$font-default-lineheight: 1.75;

$font-sizes: 12, 14, 16, 20, 24;
$sizes: (
  1: nth($font-sizes, 1),
  2: nth($font-sizes, 2),
  3: nth($font-sizes, 3),
  4: nth($font-sizes, 4),
  5: nth($font-sizes, 5)
);

// TITLE FONT
// @include title-font();

$font-heading: 'din-condensed', sans-serif;
$font-heading-weight: normal;
$font-heading-lineheight: .9;

$heading-sizes: 24, 36, 44, 54, 76;
$headings: (
  1: nth($heading-sizes, 1),
  2: nth($heading-sizes, 2),
  3: nth($heading-sizes, 3),
  4: nth($heading-sizes, 4),
  5: nth($heading-sizes, 5)
);
