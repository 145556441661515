$form-item-height: 36px;
$form-item-height-small: 26px;

$form-item-max-width: 760px;
$form-item-min-width: 50px;
$form-textarea-max-width: 920px;
$form-textarea-max-height: 600px;
$form-textarea-min-height: 200px;

$form-item-border-radius: 3px;

$form-item-border-color: $color-grey-400;
$form-item-border-color-hover: $color-grey-600;

$form-custom-size: 18px;
$form-custom-bullet-size: 10px;
$form-custom-check-size: 12px;
$form-custom-selected-color: rgba($color-primary, .99);
