// Headings

.heading {
  @include title-font();

  margin-bottom: $gutter;

  &.no-margin {
    margin-bottom: $gutter / 4;
  }
}

@each $heading, $size in $headings {

  &.heading--size-#{$heading} {
    @include font-size($size * (1.1 - ($heading / 10)));

    @include mq($bp-large) {
      @include font-size($size);
    }
  }
}
