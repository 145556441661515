.alert {
  border: 2px solid $color-border;
  display: block;
  margin-bottom: $margin-bottom;
  padding: ($gutter / 2);

  &--ok {
    border-color: $color-status-ok;
  }

  &--error {
    border-color: $color-status-error;
  }

  &--info {
    border-color: $color-secondary;
  }
}
