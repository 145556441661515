.divider {
  background-color: $color-border;
  clear: both;
  display: block;
  height: 1px;
  margin-bottom: $margin-bottom / 2;
  overflow: hidden;
  width: 100%;

  &--double {
    height: 2px;
  }
}

.border {
  border: 1px solid $color-border;

  &--bottom {
    border-bottom: 1px solid $color-border;

    &--double {
      border-bottom: 2px solid $color-border;
    }
  }

  &--v-double {
    border-bottom: 2px solid $color-border;
    border-top: 2px solid $color-border;
  }
}

.no-margin {
  margin-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */

  // .no-margin in combinatie met .heading = margin-bottom: 5px;
}

.negative-margin {
  margin-bottom: 0;
  margin-top: -$margin-bottom;
}

.margin-bottom {
  margin-bottom: $margin-bottom;

  &--x2 {
    margin-bottom: $margin-bottom * 2;
  }
}

.margin--left {
  margin-left: $gutter;
}

.margin--right {
  margin-right: $gutter;
}

.spacing {
  box-sizing: border-box;
  padding: $gutter / 2;

  &--v {
    padding: ($gutter / 2) 0;
  }

  &--h {
    padding: 0 ($gutter / 2);
  }

  &--left {
    padding: 0 0 0 ($gutter / 2);
  }

  &--right {
    padding: 0 ($gutter / 2) 0 0;
  }

  &--bottom {
    padding: 0 0 ($gutter / 2) 0;
  }

  &--top {
    padding: ($gutter / 2) 0 0 0;
  }

  &--double {
    padding: $gutter;
  }
}

.js-hidden {
  display: none;
}

.hide {
  display: none;

  @include mq(768, max) {

    &-on-mobile {
      display: none;
    }
  }

  @include mq(768) {
    @include mq(1024, max) {

      &-on-tablet {
        display: none !important; /* stylelint-disable-line declaration-no-important */
      }
    }
  }

  @include mq(1024) {

    &-on-desktop {
      display: none;
    }
  }
}

.no-border-radius {
  border-radius: 0 !important; /* stylelint-disable-line declaration-no-important */
}
