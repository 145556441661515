.list--tags {

  li {
    display: inline-block;
    margin: 2px 10px 2px 0;

    @include mq($bp-xsmall, max) {
      display: block;
      width: 100%;
    }

    span {
      border-radius: $border-radius;
      cursor: pointer;
      display: inline-block;
      padding: ($gutter / 4) ($gutter / 2);
      position: relative;

      @include mq($bp-xsmall, max) {
        display: block;
        padding-right: 60px;
      }

      .icon {
        padding: 0 0 0 ($gutter / 4);

        @include mq($bp-xsmall, max) {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      &:hover {
        background-color: $color-primary;
        color: $color-white;

        svg {
          fill: $color-white;
        }
      }
    }
  }
}

.flex--filters {
  @include mq($bp-xsmall, max) {
    flex-wrap: wrap;

    .js-removetags {
      display: inline-block;
      float: right;
      margin-top: 10px;
    }
  }
}
