.item {
  $self: &;

  border-radius: $border-radius;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  margin: 0 auto $margin-bottom;
  overflow: hidden;
  position: relative;
  width: 100%;

  &__top,
  &__bottom {
    flex: 0 0 auto;
  }

  &--noflex {
    display: block;
  }

  // @include mq($bp-small, max) {
  //   max-width: 400px;
  // }

  &__bg-image {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;

    img {
      visibility: visible;

      @include mq($bp-grid-small) {
        visibility: hidden;
      }
    }
  }

  &__label {
    left: 0;
    position: absolute;
    top: 0;
    z-index: 2;
  }

  &__discount {
    border-radius: 5000px;
    box-sizing: border-box;
    left: $gutter / 2;
    padding: 14px 7px;
    position: absolute;
    top: $gutter / 2;
    width: 60px;
    z-index: 2;

    > * {
      display: block;
    }
  }

  &--project {

    .list > li {
      margin-bottom: 2px;
      padding-left: 120px;
      position: relative;

      span {
        @include text-overflow;

        display: block;
        left: 0;
        position: absolute;
        top: 0;
        width: 120px;
      }
    }
  }
}
