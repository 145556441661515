.list--account {
  @include mq($bp-grid-large, max) {
    background: $color-white;
    border: 1px solid $color-border;
  }

  li {
    @include mq($bp-grid-large, max) {
      text-align: left;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $color-border;
    }

    a {
      display: block;
      margin: 0;
      padding: $gutter / 2;

      &:hover {
        background: $color-white;
      }
    }
  }
}
