.text,
p {
  margin-bottom: $gutter;

  // Text-sizing;

  @each $textsize, $tsize in $sizes {

    &--size-#{$textsize} {
      @include font-size($tsize);
    }
  }

  // Text-styling

  &--line-through {
    text-decoration: line-through;
  }

  &--underline {
    text-decoration: underline;
  }

  &--no-underline {
    text-decoration: none;
  }

  &--no-wrap {
    white-space: nowrap;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--overflow {
    @include text-overflow();
  }

  &--no-select {
    user-select: none;
  }
}

.line-height {

  &--xs {
    line-height: 1;
  }

  &--s {
    line-height: 1.25;
  }

  &--m {
    line-height: 1.5;
  }

  &--default {
    line-height: $font-default-lineheight;
  }

  &--heading {
    line-height: $font-heading-lineheight;
  }
}
