.band--certs {
  border-bottom: 1px solid $color-grey-lighter;
}

.certs {

  .cert {
    // border: 1px solid red;
    // display: flex;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 150px;
    padding: 0 10px;
    position: relative;

    &:not(.tns-item):not(:first-child) {
      display: none;
    }

    .figure {
      margin: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
