.site {
  background-color: $color-white;
  margin: 0 auto;
  max-width: 1600px;
  overflow: hidden;

  @include mq($bp-grid-large, max) {

    .show-menu & {
      max-height: 100vh;
    }
  }
}
