.sort {
  @include mq($bp-grid-medium, max) {
    display: block;

    .column {
      margin-left: $gutter / 2;
    }
  }

  @include mq($bp-grid-medium) {

    &__label {
      margin: 0 $gutter / 2;
    }
  }
}
