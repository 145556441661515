.amount {
  box-sizing: border-box;
  display: inline-block;
  padding-right: $gutter;
  position: relative;
  width: 90px;

  .button {
    margin: 0 !important; /* stylelint-disable-line declaration-no-important */
    position: absolute;
    right: 0;
    top: 0;

    &:last-child {
      bottom: 0;
      top: auto;
    }
  }

  .table__cell & {
    padding: 0 16px;

    @include mq($bp-responsive-table, max) {
      float: right;
      margin-right: 5px;
    }

    input {
      border-radius: 0;
      height: $form-item-height-small;
      line-height: $form-item-height-small;
    }

    .button {
      border-radius: 0;
      bottom: auto;
      height: 26px;
      left: auto;
      line-height: $form-item-height-small - 4px;
      margin: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      &:last-child {
        left: 0;
        right: auto;
      }
    }
  }
}
