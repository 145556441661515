.cart {

  .border--top {
    border-top: 1px solid $color-border;
    padding-top: 10px;
  }

  &--edit {

    .js-cart--toggle:not(.js-hidden) {
      display: none;
    }

    .js-cart--toggle.js-hidden {
      display: block;
    }
  }

  .link--remove {
    color: $color-status-error !important; /* stylelint-disable-line declaration-no-important */
    display: inline-block;
    padding-top: 5px;
  }
}
