@import 'amount',
  'custom',
  'datepicker',
  'elements',
  'focus';

.form {
  margin-bottom: $margin-bottom;
}

.control {
  margin-bottom: $margin-bottom;

  &__label {
    @include text-overflow();

    margin-bottom: $gutter / 4;

    .required {
      color: $color-grey;
      display: inline;
      font-size: 90%;
      padding-left: .3em;

      &::before,
      &::after {
        display: none;
      }
    }
  }
}
