.footer-form {

  .aanmeldennieuwsbrief {
    @media screen and (min-width: 580px) {
      margin: 0 auto;
    }

    width: fit-content;
  }

  .btn {
    background: #00538e;
    border: 2px solid #00538e;
    border-radius: 5px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    margin: 0 0 10px;
    outline: none;
    padding: 8px 12px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .15s ease-in;
    user-select: none;
    vertical-align: baseline;

    @media screen and (min-width: 480px) {
      margin-left: 10px;
    }
  }

  .umbraco-forms-field {
    margin-bottom: 5px;
  }

  .umbraco-forms-page {
    @media screen and (min-width: 480px) {
      display: flex;
      flex-wrap: nowrap;
    }

    input[type='text'].text {
      height: 34px;
    }
  }

  .meldjeaanvoordenieuwsbrief {
    @media screen and (min-width: 480px) {
      display: flex;
      flex-wrap: nowrap;
    }

    label {
      line-height: 36px;
      margin-right: 10px;
    }
  }
}
