/* stylelint-disable-line no-empty-source */

.body--website .band--nav {

  .band__content {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.body--website:not(.show-search):not(.show-menu) .band--nav {
  @include mq($bp-showmodal, max) {
    display: none;
  }
}

.menu--main {
  @include mq($bp-showmodal, max) {
    background-color: $color-grey-lighter;
    bottom: 0;
    box-sizing: border-box;
    display: none;
    left: 0;
    margin: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 80px 20px 20px !important; /* stylelint-disable-line declaration-no-important */
    position: fixed !important; /* stylelint-disable-line declaration-no-important */
    right: 0;
    top: 0;
    width: 100%;
    z-index: 105;

    .show-menu & {
      display: block;
    }

    .menu__item {
      background: $color-white;
      margin: 0 auto;
      max-width: 400px;

      > a {
        background: $color-primary;
        border-bottom: 1px solid $color-white;
        display: block;
        padding: 10px;
      }
    }

    .submenu {
      padding: ($gutter / 2 - 1px) ($gutter / 2) ($gutter / 2);

      li a {
        display: block;
        max-width: 100%;
      }
    }
  }

  @include mq($bp-showmodal) {
    display: block;
    margin: 0 !important; /* stylelint-disable-line declaration-no-important */

    .menu__item {
      display: inline-block;
      margin: 0 15px 0 0 !important; /* stylelint-disable-line declaration-no-important */
      position: relative;
      vertical-align: bottom;

      &:hover {
        z-index: 2;

        > a::after {
          opacity: 1;
          transform: scaleY(1);
        }
      }

      &.selected {

        > a::after {
          opacity: .5;
          transform: scaleY(1);
        }
      }

      > a {

        &::after {
          background-color: $color-tertiary;
          bottom: 0;
          content: '';
          display: block;
          left: -10px;
          opacity: 0;
          position: absolute;
          right: -10px;
          top: 0;
          transform: scaleY(.1);
          transform-origin: bottom;
          transition: all $transition-duration $transition-timing;
          z-index: -1;
        }

        &:hover {

          &::after {
            opacity: 1;
            transform: scaleY(1);
          }
        }
      }

      &:hover .submenu {
        display: block;
      }
    }

    .link {
      display: block;
      line-height: 30px;
      margin: 0;
      padding: 10px 0;
    }

    .submenu {
      background-color: $color-grey-lighter;
      box-shadow: 0 20px 100px 10px transparentize($color-black, .8);
      display: none;
      left: -10px;
      max-width: 380px;
      min-width: 200px;
      padding: 5px 0;
      position: absolute;
      top: 100%;

      .link {
        padding: 0 10px;
        white-space: nowrap;
      }
    }
  }
}
