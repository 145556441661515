.icon {
  display: inline-block;
  height: 2px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 16px;

  &--spacing {
    margin: 0 5px;
  }

  > svg {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all $transition-duration $transition-timing;
    width: 96%;
  }

  &--size-1 {
    height: 9px;
    width: 7px;

    > svg {
      max-height: 9px;
    }
  }

  &--size-2 {
    height: 18px;
    width: 18px;

    > svg {
      max-height: 18px;
    }
  }

  &--size-3 {
    height: 21px;
    width: 18px;

    > svg {
      max-height: 21px;
    }
  }

  &--size-4 {
    height: 24px;
    width: 24px;

    > svg {
      max-height: 24px;
    }
  }

  &--size-5 {
    height: 40px;
    width: 40px;
  }

  svg {
    max-width: 100%;
  }
}
