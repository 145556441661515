.body--webshop {

  &.show-menu .band--nav {
    z-index: 105;
  }

  .account-wrapper {

    .button {
      margin-bottom: 20px;
    }
  }

  .band--footer {
    @include mq($bp-medium, max) {
      padding-top: 20px;
    }
  }
}
