input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
input[type='tel'],
select,
textarea {
  @include font-size(nth($font-sizes, 2));

  appearance: none;
  background-color: $color-white;
  background-position: 98% 50% !important; /* stylelint-disable-line declaration-no-important */
  background-repeat: no-repeat !important; /* stylelint-disable-line declaration-no-important */
  border: 1px solid $form-item-border-color;
  border-radius: $form-item-border-radius;
  box-sizing: border-box;
  font-family: $font-default;
  height: $form-item-height;
  line-height: $form-item-height;
  margin: 0;
  max-width: $form-item-max-width;
  min-width: $form-item-min-width;
  outline: none;
  padding: 0 ($gutter * 2) 0 ($gutter / 2);
  transition: all $transition-duration $transition-timing;
  width: 100%;

  &.field--small {
    height: $form-item-height-small;
    line-height: $form-item-height-small;
  }

  &:hover,
  &:focus {
    border: 1px solid $form-item-border-color-hover;
  }

  &::placeholder {
    color: $color-grey;
    font-style: normal;
  }

  .interacted & {

    &:required:valid {
      background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24"><polygon fill="#{$rgba-color-status-ok}" points="22,6.1 20.9,5 9.1,16.7 3.2,10.9 2.1,12 9.1,19 "/></svg>');
    }

    &:required:invalid {
      background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24"><polygon fill="#{$rgba-color-status-error}" points="19,6.1 17.9,5 12,10.9 6.1,5 5,6.1 10.9,12 5,17.9 6.1,19 12,13.1 17.9,19 19,17.9 13.1,12" /></svg>');
      border-color: $color-status-error;
    }
  }
}

input[type='checkbox'] {

  .form--sent & {

    &:required:invalid + span {
      color: $color-status-error;
    }
  }
}

input[type='number'],
input[type='tel'] {
  padding-right: $gutter / 2;

  &::-webkit-inner-spin-button {
    appearance: none;
  }
}

textarea {
  background-position: 98% 10px;
  height: auto;
  line-height: $font-default-lineheight;
  max-height: $form-textarea-max-height;
  max-width: $form-textarea-max-width;
  min-height: $form-textarea-min-height;
  padding-top: $gutter / 2;
  resize: vertical;
}

select {
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24"><path fill="#{$rgba-color-grey-light}" d="M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z"></path></svg>');
  background-position: 98% 50%;
  background-repeat: no-repeat;
  cursor: pointer;
  padding: 0 30px 0 ($gutter / 2);

  /* stylelint-disable */

  _:-ms-fullscreen,
  :root & {
    padding: 0 0 0 $gutter / 2;
  }

  /* stylelint-enable */

  &:hover {
    background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24"><path fill="#{$rgba-color-grey}" d="M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z"></path></svg>');
  }

  .interacted & {

    &:required:valid {
      background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24"><path fill="#{$rgba-color-grey}" d="M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z"></path></svg>');
    }

    &:required:invalid {
      background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24"><path fill="#{$rgba-color-status-error}" d="M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z"></path></svg>');
    }
  }
}

.umbraco-forms-field {
  margin-bottom: $gutter;

  &.singlechoice {

    .control__field label {
      margin-bottom: $gutter / 4;
    }
  }
}
