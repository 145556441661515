.row {
  @include debug(pink);

  display: flex;
  flex-wrap: wrap;
  margin-bottom: $margin-bottom;
  width: 100%;

  &--nowrap {
    flex-wrap: nowrap;
  }

  &--noflex {
    display: block;
  }

  &--spacing {
    @include debug(orange);

    left: -10px;
    position: relative;
    width: calc(100% + #{$gutter});
  }

  &--responsive {
    @include mq($bp-xsmall, max) {
      display: block !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  // Breakpoints

  @include mq($bp-grid-small, max) {

    &--bp-small {
      display: block;
      left: 0;
      width: 100%;

      > .column {
        display: block;
        margin: 0 !important; /* stylelint-disable-line declaration-no-important */
        max-width: inherit !important; /* stylelint-disable-line declaration-no-important */
        width: 100%;

        &.margin-bottom {
          margin-bottom: 20px !important; /* stylelint-disable-line declaration-no-important */

          &--x2 {
            margin-bottom: 40px !important; /* stylelint-disable-line declaration-no-important */
          }
        }
      }
    }
  }

  @include mq($bp-grid-medium, max) {

    &--bp-medium {
      display: block;
      left: 0;
      width: 100%;

      > .column {
        display: block;
        margin: 0 !important; /* stylelint-disable-line declaration-no-important */
        max-width: inherit !important; /* stylelint-disable-line declaration-no-important */
        width: 100%;

        &.margin-bottom {
          margin-bottom: 20px !important; /* stylelint-disable-line declaration-no-important */

          &--x2 {
            margin-bottom: 40px !important; /* stylelint-disable-line declaration-no-important */
          }
        }
      }
    }
  }

  @include mq($bp-grid-large, max) {

    &--bp-large {
      display: block;
      left: 0;
      width: 100%;

      > .column {
        display: block;
        margin: 0 !important; /* stylelint-disable-line declaration-no-important */
        max-width: inherit !important; /* stylelint-disable-line declaration-no-important */
        width: 100%;

        &.margin-bottom {
          margin-bottom: 20px !important; /* stylelint-disable-line declaration-no-important */

          &--x2 {
            margin-bottom: 40px !important; /* stylelint-disable-line declaration-no-important */
          }
        }
      }
    }
  }
}

.column {
  @include debug(red);

  box-sizing: border-box;
  flex: 1 1 auto;

  &--border {
    border: 1px solid $color-border;
  }

  .row--spacing > & {
    margin: 0 ($gutter / 2);
  }

  @include mq($bp-xsmall, max) {

    .row--responsive > & {
      max-width: inherit !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  &--flex {
    display: flex;
  }
}

.flex {

  &--w25 {
    flex: 0 1 25%;
    max-width: 25%;

    .row--spacing > & {
      max-width: calc(25% - #{$gutter});
    }

    @include mq($bp-xsmall) {
      @include mq($bp-grid-medium-responsive, max) {

        .row--responsive > & {
          flex: 0 1 50%;
          max-width: 50%;
        }

        .row--responsive.row--spacing > & {
          max-width: calc(50% - #{$gutter});
        }
      }
    }
  }

  &--w33 {
    flex: 0 1 33.3%;
    max-width: 33.3%;

    .row--spacing > & {
      max-width: calc(33.3% - #{$gutter});
    }

    @include mq($bp-xsmall) {
      @include mq($bp-grid-medium, max) {

        .row--responsive > & {
          flex: 0 0 100%;
          margin-left: auto;
          margin-right: auto;
          max-width: 500px;
        }

        // .row--responsive.row--spacing > & {
        //   max-width: calc(100% - #{$gutter});
        // }
      }
    }
  }

  &--w40 {
    flex: 0 0 40%;
    max-width: 40%;

    .row--spacing > & {
      max-width: calc(40% - #{$gutter});
    }
  }

  &--w50 {
    flex: 0 0 50%;
    max-width: 50%;

    .row--spacing > & {
      max-width: calc(50% - #{$gutter});
    }
  }

  &--w60 {
    flex: 0 0 60%;
    max-width: 60%;

    .row--spacing > & {
      max-width: calc(60% - #{$gutter});
    }
  }

  &--w66 {
    flex: 0 0 66.6%;
    max-width: 66.6%;

    .row--spacing > & {
      max-width: calc(66.6% - #{$gutter});
    }
  }

  &--w75 {
    flex: 0 0 75%;
    max-width: 75%;

    .row--spacing > & {
      max-width: calc(75% - #{$gutter});
    }
  }

  &--w100 {
    flex: 1 1 100%;
    max-width: 100%;
  }

  @each $name,
    $size
    in (
      10: 10,
      20: 20,
      30: 30,
      40: 40,
      50: 50,
      60: 60,
      70: 70,
      80: 80,
      90: 90,
      100: 100,
      25: 25,
      75: 75,
      33: 33.33,
      66: 66.66
    ) {
    @include mq($bp-xsmall) {

      &--xs-w#{$name} {
        flex: 0 0 #{$size + '%'};
        max-width: #{$size + '%'};

        .row--spacing > & {
          max-width: calc(#{$size + '%'} - #{$gutter});
        }
      }
    }

    @include mq($bp-small) {

      &--s-w#{$name} {
        flex: 0 0 #{$size + '%'};
        max-width: #{$size + '%'};

        .row--spacing > & {
          max-width: calc(#{$size + '%'} - #{$gutter});
        }
      }
    }

    @include mq($bp-medium) {

      &--m-w#{$name} {
        flex: 0 0 #{$size + '%'};
        max-width: #{$size + '%'};

        .row--spacing > & {
          max-width: calc(#{$size + '%'} - #{$gutter});
        }
      }
    }

    @include mq($bp-large) {

      &--l-w#{$name} {
        flex: 0 0 #{$size + '%'};
        max-width: #{$size + '%'};

        .row--spacing > & {
          max-width: calc(#{$size + '%'} - #{$gutter});
        }
      }
    }

    @include mq($bp-xlarge) {

      &--xl-w#{$name} {
        flex: 0 0 #{$size + '%'};
        max-width: #{$size + '%'};

        .row--spacing > & {
          max-width: calc(#{$size + '%'} - #{$gutter});
        }
      }
    }
  }

  &--no-stretch {
    flex: 0 0 auto;
  }

  &--align-top {
    align-items: flex-start;
  }

  &--align-middle {
    align-items: center;
  }

  &--align-bottom {
    align-items: flex-end;
  }
}
