.dp-modal {
  background: rgba(255, 255, 255, .75);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999999;
}

.dp {
  background: $color-white;
  border-radius: 4px;
  box-shadow: 2px 2px 16px rgba(0, 0, 0, .25);
  line-height: 1.4;
  max-height: 400px;
  overflow: hidden;
  padding-top: 6px;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  z-index: 1000;

  &::before {
    background: $color-primary;
    background: linear-gradient(-90deg, $color-primary 0%, $color-tertiary 100%);
    content: ' ';
    height: 6px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .dp-permanent & {
    border: 1px solid $color-border;
    box-shadow: none;
    padding-top: 0;
    z-index: 0;

    &::before {
      display: none;
    }
  }
}

.dp-cal {
  min-height: 300px;
}

.dp-below {
  font-size: .8em;
  max-width: 100vw;
  position: absolute;
  width: 400px;
}

.dp-permanent {
  font-size: .8em;
  max-width: 100vw;
  position: relative;
  width: 400px;
}

.dp-modal .dp {
  animation: slide-up .3s forwards;
  left: 50%;
  max-width: 600px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 4em);
}

.dp-months {
  padding: 24px;
}

.dp-years {
  box-sizing: border-box;
  max-height: 400px;
  overflow: auto !important; /* stylelint-disable-line declaration-no-important */
  padding: 8px 0;
}

.dp-cal-month,
.dp-cal-year,
.dp-day,
.dp-month,
.dp-year {
  background: transparent;
  border: 0;
  border-radius: 2px;
  box-sizing: border-box;
  color: $color-text;
  position: relative;
  text-align: center;
  text-decoration: none;
}

.dp-cal-header {
  background: $color-grey-lighter;
  padding-bottom: 16px;
  position: relative;
  text-align: center;
}

.dp-next,
.dp-prev {
  background: transparent;
  border: 0;
  border-radius: 2px;
  color: $color-grey;
  height: 30px;
  overflow: hidden;
  position: absolute;
  top: 14px;
  width: 30px;

  &:focus,
  &:hover {
    color: inherit;
    outline: none;
  }

  &::before {
    border: 2px solid;
    content: '';
    display: inline-block;
    height: 10px;
    margin: 9px 0 40px 4px;
    transform: rotate(-45deg);
    transition: border-color .2s;
    width: 10px;
  }
}

.dp-prev {
  left: 24px;

  &::before {
    border-bottom: 0;
    border-right: 0;
  }
}

.dp-next {
  right: 24px;

  &::before {
    border-left: 0;
    border-top: 0;
    margin-left: 0;
    margin-right: 4px;
  }
}

.dp-cal-month,
.dp-cal-year {
  display: inline-block;
  font-size: 1.2em;
  font-weight: bold;
  outline: none;
  padding: 16px 8px 8px;
}

.dp-cal-footer {
  background: $color-grey-lighter;
  text-align: center;
}

.dp-day-today::after {
  border: 1px solid $color-border;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.dp-close,
.dp-clear,
.dp-today {
  background: transparent;
  border: 0;
  box-sizing: border-box;
  color: inherit;
  display: inline-block;
  padding: 8px;
  text-decoration: none;
  width: 33%;
}

.dp-permanent .dp-close,
.dp-permanent .dp-clear {
  display: none;
}

.dp-close:active,
.dp-clear:active,
.dp-today:active,
.dp-next:active,
.dp-prev:active,
.dp-cal-month:active,
.dp-cal-year:active {
  background: $color-tertiary;
  color: $color-white;
}

@media screen and (min-device-width: 1200px) {

  .dp-close:hover,
  .dp-close:focus,
  .dp-clear:hover,
  .dp-clear:focus,
  .dp-today:hover,
  .dp-today:focus,
  .dp-next:hover,
  .dp-next:focus,
  .dp-prev:hover,
  .dp-prev:focus,
  .dp-cal-month:focus,
  .dp-cal-month:hover,
  .dp-cal-year:hover,
  .dp-cal-year:focus {
    background: $color-tertiary;
    color: $color-white;
  }
}

.dp-col-header,
.dp-day {
  @include font-size(14);

  display: inline-block;
  padding: 8px;
  text-align: center;
  width: 14.28571429%;
}

.dp-col-header {
  color: $color-grey;
  font-size: .8em;
  font-weight: 300;
  padding: 8px 0;
  text-transform: uppercase;
}

.dp-month {
  display: inline-block;
  padding: 8px;
  width: 33%;
}

.dp-year {
  display: block;
  padding: 8px 40px;
  width: 100%;
}

.dp-edge-day {
  color: $color-grey;
}

.dp-day:hover,
.dp-month:hover,
.dp-year:hover,
.dp-current:focus,
.dp-current,
.dp-day:focus,
.dp-month:focus,
.dp-year:focus {
  background: $color-tertiary;
  color: $color-white;
  outline: none;
}

.dp-selected:hover,
.dp-selected:focus,
.dp-selected {
  background: $color-tertiary;
  color: $color-white;
}

.dp-day-disabled {
  background: transparent;
  color: $color-grey-light;
}

.dp-day-disabled:focus,
.dp-day-disabled:hover {
  background: $color-grey-light;
}

.dp-focuser {
  left: 50%;
  position: absolute;
  top: 50%;
  z-index: 0;
}

/* Responsive overrides */
@media (max-width: 480px), (max-height: 480px) {

  .dp-modal .dp {
    font-size: .9em;
    width: auto;
    width: 100%;
  }

  .dp-day-of-week,
  .dp-day {
    padding: 8px;
  }
}

@keyframes slide-up {

  0% {
    transform: translate(-50%, 100%);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}
