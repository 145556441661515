.status {
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  margin-right: $gutter / 2;
  vertical-align: middle;
  width: 12px;

  .table & {
    margin: 0;

    @include mq($bp-responsive-table) {
      display: block;
    }
  }

  &--available {
    background-color: $color-status-ok;
  }

  &--out-of-stock {
    background-color: $color-status-error;
  }

  &--critical {
    background-color: $color-secondary;
  }
}
