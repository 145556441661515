.product {
  $self: &;

  &--fullwidth {

    #{ $self }__column {

      &--price {
        @include mq($bp-grid-medium) {
          text-align: right;
        }
      }
    }
  }

  &--detail {

    &__order {

      select,
      .button {
        max-width: 340px;
      }
    }
  }

  > .row {

    > .column > .row {
      @include mq($bp-grid-medium) {
        flex-wrap: nowrap;
      }
    }
  }
}
