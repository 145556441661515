.color {

  &--blue {
    color: $color-primary;
  }

  &--orange {
    color: $color-secondary;
  }

  &--grey-light {
    color: $color-grey-light;
  }

  &--grey {
    color: $color-grey;
  }

  &--white {
    color: $color-white;
  }

  &--black {
    color: $color-black;
  }
}

.bgcolor {

  &--grey {
    background-color: $color-grey-lighter;
  }

  &--blue {
    background-color: $color-primary;
  }

  &--orange {
    background-color: $color-secondary;
  }
}

.fill {

  &--blue > svg {
    fill: $color-primary;
  }

  &--orange > svg {
    fill: $color-secondary;
  }

  &--grey-light > svg {
    fill: $color-grey-light;
  }

  &--grey > svg {
    fill: $color-grey;
  }

  &--white > svg {
    fill: $color-white;
  }

  &--black > svg {
    fill: $color-black;
  }
}
