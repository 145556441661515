.button {
  @include font-size(nth($font-sizes, 2));

  background: $color-primary;
  border: 2px solid $color-primary;
  border-radius: $border-radius;
  box-sizing: border-box;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  line-height: 1;
  margin: 0 0 $gutter / 2;
  outline: none;
  padding: 8px 12px;
  text-decoration: none;
  transition: all $transition-duration $transition-timing;
  user-select: none;
  vertical-align: baseline;

  .table & {
    display: block;
  }

  &--block {
    display: block;
    width: 100%;
  }

  &--large {
    height: 48px;
    line-height: 44px;
    min-width: 48px;
    padding: 0 6px;
    text-align: center;
  }

  &--small {
    height: 26px;
    line-height: 22px;
    min-width: 26px;
    padding: 0 6px;
    text-align: center;
  }

  &--mini {
    height: 17px;
    line-height: 12px;
    min-width: 17px;
    padding: 0 2px;
    text-align: center;
  }

  &--circle {
    border-radius: 5000px;
  }

  svg {
    fill: $color-white;
  }

  // STYLING
  // .button--style-1 😬

  &:hover {
    background: $color-hover;
    border-color: $color-hover;
    color: $color-white;
  }

  &--style-2 {
    background: $color-secondary;
    border: 2px solid $color-secondary;
    color: $color-white;

    &:hover {
      background: $color-white;
      border-color: $color-secondary;
      color: $color-secondary;

      svg {
        fill: $color-secondary;
      }
    }
  }

  &--style-3 {
    background: $color-white;
    border: 2px solid $color-primary;
    color: $color-primary;

    svg {
      fill: $color-primary;
    }

    &:hover {
      background: $color-hover;
      border-color: $color-hover;
      color: $color-white;

      svg {
        fill: $color-white;
      }
    }
  }

  &--style-4 {
    background: $color-white;
    border: 2px solid $color-border;
    color: $color-primary;

    svg {
      fill: $color-primary;
    }

    &:hover {
      background: $color-white;
      border-color: $color-hover;
      color: $color-primary;

      svg {
        fill: $color-primary;
      }
    }
  }

  &--style-5 {
    background: $color-primary;
    border: 2px solid $color-white;
    color: $color-white;

    &:hover {
      background: $color-white;
      border-color: $color-hover;
      color: $color-primary;

      svg {
        fill: $color-primary;
      }
    }
  }
}
