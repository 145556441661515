.carousel {

  &__item {

    &:not(.tns-item):not(:first-child) {
      display: none;
    }
  }
}

.tns-outer {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: $margin-bottom;

  @include mq($bp-grid-medium, max) {

    .band:not(.band--certs) & {
      left: 50%;
      position: relative;
      transform: translateX(-50%);
      width: 100vw;
    }
  }

  .band--certs & {

    .tns-inner {
      margin-bottom: 10px;
      padding: 0 20px 0 0;
    }
  }

  [hidden] {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }

  [aria-controls],
  [data-action] {
    cursor: pointer;
  }

  &.ms-touch {
    -ms-overflow-style: none;
    overflow-x: scroll;
    overflow-y: hidden;
    // -ms-scroll-chaining: none;
    scroll-snap-points-x: snapinterval(0%, 100%);
    scroll-snap-type: mandatory;
  }
}

.tns-slider {
  transition: all 0s;

  > .tns-item {
    box-sizing: border-box;
  }
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;

  > .tns-item {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
  }
}

.tns-horizontal.tns-no-subpixel {

  &::after {
    clear: both;
    content: '';
    display: table;
  }

  > .tns-item {
    float: left;
    margin-right: -100%;
  }
}

.tns-no-calc {
  left: 0;
  position: relative;
}

.tns-gallery {
  left: 0;
  min-height: 1px;
  position: relative;

  > .tns-item {
    left: -100%;
    position: absolute;
    transition: transform 0s, opacity 0s;
  }

  > .tns-moving {
    transition: all .25s;
  }
}

.tns-lazy-img {
  opacity: .6;
  transition: opacity .6s;

  &.loaded {
    opacity: 1;
  }
}

.tns-ah {
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-hdx {
  overflow-x: hidden;
}

.tns-hdy {
  overflow-y: hidden;
}

.tns-visually-hidden {
  left: -10000em;
  position: absolute;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  z-index: 0;
}

.tns-normal,
.tns-fadeOut {
  opacity: 0;
  z-index: -1;
}

.tns-nav {
  position: relative;
  text-align: center;
  top: -5px;

  button {
    background-color: $color-grey-lighter;
    border: 0;
    border-radius: 50%;
    display: inline-block;
    height: 12px;
    margin: 0 2px;
    outline: 0;
    padding: 0;
    width: 12px;

    &:hover {
      background-color: $color-grey;
    }

    &.tns-nav-active {
      background-color: $color-primary !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}
