.band--nav {
  position: relative;
  z-index: 102;

  .band__content {
    padding: ($gutter / 2) $gutter;
    position: relative;
    z-index: 101;

    &:nth-child(1) {
      z-index: 100;

      @include mq($bp-showmodal) {
        z-index: 102;
      }
    }
  }

  .show-account &,
  .show-search:not(.body--website) & {
    z-index: 0;
  }
}

.menu-overlay {
  display: none;

  @include mq($bp-showmodal) {

    .show-menu & {
      // background: yellow;
      bottom: 0;
      display: block;
      height: calc(100vh - 240px);
      left: 50%;
      position: fixed;
      right: 0;
      top: 0;
      transform: translateX(-50%);
      width: 100vw;
      z-index: 2;
    }
  }
}

.menu-wrapper {
  background-color: $color-grey-lighter;
  box-sizing: border-box;
  display: none;
  overflow: hidden;
  width: 100%;

  @include mq($bp-showmodal, max) {
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 80px !important; /* stylelint-disable-line declaration-no-important */
    position: fixed !important; /* stylelint-disable-line declaration-no-important */
    right: 0;
    top: 0;
    z-index: 1;
  }

  @include mq($bp-showmodal) {
    left: 50%;
    position: absolute !important; /* stylelint-disable-line declaration-no-important */
    transform: translateX(-50%);
  }

  .show-menu & {
    display: block;

    @include mq($bp-showmodal) {
      box-shadow: 0 300px 1000px 100px transparentize($color-black, .6);
    }
  }
}

// SHOP-MENU

.nav--shop {
  // border: 1px solid red;
  margin: 0 auto $margin-bottom;
  max-width: 1120px;
  position: relative;

  @include mq($bp-showmodal, max) {
    border: 1px solid $color-border;
    // margin-bottom: $margin-bottom;
  }

  > li {
    box-sizing: border-box;
    padding: 0;

    @include mq($bp-showmodal) {
      width: 300px;
    }

    > a {
      box-sizing: border-box;
      color: $color-text;
      display: block;
      padding: ($gutter / 2);
      position: relative;
      width: 100%;

      @include mq($bp-showmodal, max) {
        background-color: $color-white;
        border-bottom: 1px solid $color-border;
      }

      .icon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);

        svg {
          height: 15px;
          width: 15px;
        }
      }

      @include mq($bp-showmodal) {

        &:hover {
          background-color: $color-grey-lighter;
        }

        &::after {
          background: $color-white;
          content: '';
          display: block;
          height: 100vh;
          pointer-events: none;
          position: absolute;
          right: 0;
          top: -10px;
          width: 100vw;
          z-index: -1;
        }
      }
    }

    &.js-show-submenu {

      > a {
        background-color: $color-grey-lighter;

        @include mq($bp-showmodal, max) {
          background-color: $color-primary;
          color: $color-white;

          .icon svg {
            fill: $color-white;
            right: -5px;
            transform: rotate(90deg);
            transform-origin: center top;
          }
        }
      }
    }
  }
}

.nav--shop--sub {
  display: none;

  @include mq($bp-showmodal) {
    left: 300px;
    padding: 0 ($gutter / 2);
    position: absolute;
    right: 0;
    top: 0;
  }

  .js-show-submenu & {
    display: block;

    @include mq($bp-grid-small) {
      display: flex;
      flex-wrap: wrap;
    }

    @include mq($bp-showmodal, max) {
      background: $color-border;
      padding: $gutter 0;
    }
  }

  &__header {
    display: none;
    padding: ($gutter / 2);

    @include mq($bp-showmodal) {
      display: block;
      flex: 0 0 100% !important; /* stylelint-disable-line declaration-no-important */
      max-width: 100% !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  > li {
    box-sizing: border-box;

    @include mq($bp-grid-small) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include mq($bp-grid-medium) {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }

    > a {
      display: block;
      padding: ($gutter / 4) ($gutter / 2);
    }
  }
}
