.paging {
  display: flex;
  justify-content: center;

  .button {
    margin: 0 2px;
  }

  &--disabled,
  &--disabled:hover {
    border-color: $color-grey-lighter !important; /* stylelint-disable-line declaration-no-important */
    color: $color-grey !important; /* stylelint-disable-line declaration-no-important */
    cursor: default;

    .icon svg {
      fill: $color-grey;
    }
  }

  &--number {
    border-color: $color-white !important; /* stylelint-disable-line declaration-no-important */

    @include mq($bp-small, max) {
      padding-left: 8px !important; /* stylelint-disable-line declaration-no-important */
      padding-right: 8px !important; /* stylelint-disable-line declaration-no-important */
    }

    &:hover {
      border-color: $color-grey !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}
