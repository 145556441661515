label {
  display: block;
  position: relative;

  span {
    cursor: pointer;
    display: block;
    padding-left: $form-custom-size + $gutter / 2;
    position: relative;
    user-select: none;

    &::after,
    &::before {
      border: 1px solid $form-item-border-color;
      content: '';
      display: block;
      height: $form-custom-size;
      left: 0;
      line-height: $form-custom-size;
      overflow: hidden;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      transition: all $transition-duration $transition-timing;
      width: $form-custom-size;
    }

    &::after {
      border: 0;
      color: $form-custom-selected-color;
      opacity: 0;
    }

    &:hover {

      &::before {
        border-color: $form-item-border-color-hover;
      }
    }
  }

  input[type='radio'] {
    display: none;

    + span {

      &::after {
        background: $form-item-border-color;
        height: $form-custom-bullet-size;
        left: (($form-custom-size + 2px) - $form-custom-bullet-size) / 2;
        padding: 0;
        width: $form-custom-bullet-size;
      }

      &::after,
      &::before {
        border-radius: 50%;
      }

      &:hover {

        &::after {
          opacity: .5;
        }
      }
    }

    &:checked + span {
      // font-weight: bold;

      &::after {
        background: $form-custom-selected-color;
        opacity: 1;
      }
    }
  }

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    z-index: -1;

    + span {

      &::after {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#{$form-custom-selected-color}" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"/></svg>');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        height: $form-custom-check-size;
        left: ($form-custom-size + 2 - $form-custom-check-size) / 2;
        width: $form-custom-check-size;
      }
    }

    &:checked + span {

      &::after {
        opacity: 1;
      }
    }
  }
}
