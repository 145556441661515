.band {
  @include debug(blue);

  clear: both;
  min-width: 300px;
  position: relative;

  &__content {
    @include debug(yellow);

    margin: 0 auto;
    max-width: 1120px;
    padding: 0 $gutter;
    position: relative;
    z-index: 2;

    &--full {
      max-width: inherit;
      padding: 0;
    }

    &--padding {
      padding-bottom: $gutter;
      padding-top: $gutter;
    }

    .band__content {
      padding-left: 0;
      padding-right: 0;

      *:last-child {
        margin-bottom: 0;
      }
    }

    .band {
      min-width: 0;

      @include mq($bp-grid-medium) {

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.band--usps {

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: $color-hover;
    }
  }
}
